<template>
    <div class="history-order">
        <div class="container">
<!--            <router-link :to="{ name: 'history'}" class="go-back">
                Повернутися до історії замовлень
            </router-link>-->
            <a href="javascript:void(0);" class="go-back" @click="$router.go(-1)">
              Повернутися
            </a>
            <div :class="['history-order__wrapp',{'disabled':documentDisabled}]">
                <div class="history-order__item">
                    <div class="history-order__head">
                        Страховий продукт
                    </div>
                    <div class="history-order__form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <label>Компанія</label>
                                <input type="text" class="form-control" v-model="order.companyInfo.name" disabled>
                            </div>
                            <div class="col-md-4">
                                <label>Ліміт покриття</label>
                                <input type="text" class="form-control" v-model="order.offer.coverage" disabled>
                            </div>
<!--                            <div class="col-md-4">-->
<!--                                <label>Тариф</label>-->
<!--                                <input type="text"  class="form-control" v-model="order.insuranceObject.vin" disabled>-->
<!--                            </div>-->
                        </div>
                    </div>
<!--                    <div v-if="order.dgo" class="history-order__form-group">
                        <div class="history-order__form-heading">ДЦВ:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Вартість, грн</label>
                                <input  disabled v-model="order.dgo.price" class="form-control date-selector">
                            </div>
                            <div class="col-md-4">
                                <label>Компенсація, грн</label>
                                <input  disabled v-model="order.dgo.compensation" class="form-control date-selector">
                            </div>
                        </div>
                    </div>-->
                </div>

                <div class="history-order__item">
                    <div class="history-order__head">
                        Розрахунок
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Дата дії договору:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Початок</label>
                                <input  v-if="skeletonOff" disabled v-model="order.contract.startedAt" class="form-control date-selector">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Закінчення</label>
                                <input v-if="skeletonOff"  disabled v-model="order.contract.finishedAt" class="form-control date-selector">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Розрахунковий період</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="orderPeriod" disabled>
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
<!--                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Тип ТЗ (категорія):</div>
                        <div class="row">
                            <div class="col-md-8">
                                <input v-if="skeletonOff"  :disabled="documentDisabled" :value="showAutoCategory()" class="form-control" >
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Місце реєстрації власника ТЗ:</div>
                        <div class="row">
                            <div class="col-md-8 form-group">
                                <input v-if="skeletonOff"  :disabled="documentDisabled" :value="showCityRegister()" class="form-control" >
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="history-order__notification col-md-8">
                                <div class="history-order__notification__wrapp">
                                    <div class="history-order__notification__icon">
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 23.4C15.7583 23.4 18.4035 22.3043 20.3539 20.3539C22.3043 18.4035 23.4 15.7583 23.4 13C23.4 10.2417 22.3043 7.59647 20.3539 5.64609C18.4035 3.69571 15.7583 2.6 13 2.6C10.2417 2.6 7.59647 3.69571 5.64609 5.64609C3.69571 7.59647 2.6 10.2417 2.6 13C2.6 15.7583 3.69571 18.4035 5.64609 20.3539C7.59647 22.3043 10.2417 23.4 13 23.4ZM13 26C5.8201 26 0 20.1799 0 13C0 5.8201 5.8201 0 13 0C20.1799 0 26 5.8201 26 13C26 20.1799 20.1799 26 13 26ZM11.7 18.2H14.3V20.8H11.7V18.2ZM11.7 5.2H14.3V15.6H11.7V5.2Z" fill="#FA9A13"/>
                                        </svg>
                                        <b>Важливо</b>
                                    </div>
                                    <p>Місце реєстрації (проживання) власника транспортного засобу (фізичної особи) або місцезнаходження юридичної особи відповідно до документа про реєстрацію транспортного засобу</p>
                                </div>
                            </div>
                        </div>
                    </div>-->
                </div>
                <div class="history-order__item history-order__client">
                    <div class="history-order__head">
                        Дані страхувальника
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Ідентифікація:</div>
                        <div class="row">
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>Повне ім'я</label>
                                <input v-if="skeletonOff" type="text" placeholder="" class="form-control" v-model="order.insurant.fullName" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div v-if="! isLegalType()" class="col-md-4">
                                <label>Дата народження</label>
                                <input v-if="skeletonOff" v-model="order.insurant.dateBirthAt" class="form-control date-selector" placeholder="" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Серія закордонного паспорту</label>
                                <input v-if="skeletonOff" type="text" placeholder="Запись №" class="form-control" v-model="order.insurant.documentation.serial" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="!this.isDocumentERetiree(order.insurant.privilegeTypeId)" class="col-md-4">
                                <label>Номер закордонного паспорту</label>
                                <input v-if="skeletonOff" type="text" placeholder="Документ №" class="form-control" v-model="order.insurant.documentation.number" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>{{ !this.isDocumentERetiree(order.insurant.privilegeTypeId) ? 'Дата выдачі' : 'Закінчення дії' }}</label>
                                <input v-if="skeletonOff" v-model="order.insurant.documentation.dateOfIssue" class="form-control date-selector" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>{{isLegalType() ? 'ЄДРПОУ' : 'ІПН' }}</label>
                                <input v-if="skeletonOff" type="text" class="form-control" v-model="order.insurant.documentation.uin" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                    <div class="history-order__form-group">
                        <div class="history-order__form-heading">Контактна інформація:</div>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Телефон</label>
                                <input v-if="skeletonOff" type="text" placeholder="Телефон" class="form-control" v-model="order.insurant.phone" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Email</label>
                                <input v-if="skeletonOff" type="text" placeholder="Email" class="form-control" v-model="order.insurant.email" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                            <div class="col-md-4">
                                <label>Адреса</label>
                                <input v-if="skeletonOff" type="text" placeholder="Адреса" class="form-control" v-model="order.insurant.address" :disabled="documentDisabled">
                                <PuSkeleton v-else class="skeleton-input"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__item">
                    <div class="history-order__head">
                        Дані учасників
                    </div>
                    <div class="history-order">
                        <div class="history-order__form-heading">Ідентифікація:</div>
                        <div class="history-order__form-group" v-for="item in order.insurants" :key="item.id" :item="item">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Повне ім'я</label>
                                    <input v-if="skeletonOff" type="text" placeholder="Повне ім'я" class="form-control" v-model="item.fullName" disabled>
                                    <PuSkeleton v-else class="skeleton-input"/>
                                </div>
                                <div class="col-md-4">
                                    <label>Дата народження</label>
                                    <input v-if="skeletonOff" type="text" placeholder="Дата народження" class="form-control" v-model="item.dateBirthAt" disabled>
                                    <PuSkeleton v-else class="skeleton-input"/>
                                </div>
                                <div class="col-md-4">
                                    <label>Серія закордонного паспорту</label>
                                    <input v-if="skeletonOff" type="text" placeholder="Серія закордонного паспорту" class="form-control" v-model="item.documentation.serial" :disabled="documentDisabled">
                                    <PuSkeleton v-else class="skeleton-input"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>Номер закордонного паспорту</label>
                                    <input v-if="skeletonOff" type="text" placeholder="Номер закордонного паспорту" class="form-control" v-model="item.documentation.number" :disabled="documentDisabled">
                                    <PuSkeleton v-else class="skeleton-input"/>
                                </div>
                                <div class="col-md-4">
                                    <label>Дата выдачі</label>
                                    <input v-if="skeletonOff" v-model="item.documentation.dateOfIssue" class="form-control date-selector" :disabled="documentDisabled">
                                    <PuSkeleton v-else class="skeleton-input"/>
                                </div>
                                <div class="col-md-4">
                                    <label>{{isLegalType() ? 'ЄДРПОУ' : 'ІПН' }}</label>
                                    <input v-if="skeletonOff" type="text" class="form-control" v-model="item.documentation.uin" :disabled="documentDisabled">
                                    <PuSkeleton v-else class="skeleton-input"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="history-order__price">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="history-order__price__item">
                                <p>Платіж:</p>
                                <b> {{ showPrice() }} <span>грн</span></b>
                            </div>
                            <div v-if="showDiscountPrice()" class="history-order__price__item">
                                <p>З урахуванням знижки:</p>
                                <b> {{ showDiscountPrice() }} <span>грн</span></b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-order__nav">
                    <div class="row">

                    </div>
                </div>

                <div v-if="this.loadData" class="partner-order__nav">
                    <div class="row">
                        <div class="col-md-8" v-if="order.edit">
                            <div class="row">
                                <div v-if="order.statusId == 1" class="col-md-6">
                                    <button type="button" @click="declare()" class="btn-blue-w">Редагувати і заявити</button>
                                </div>
                                <div v-if="order.statusId == 5" class="col-md-6">
                                    <button type="button" @click="conclude()" class="btn-blue-w">Оформити</button>
                                </div>
                                <div class="col-md-6">
                                    <button type="button" @click="draft()" class="btn btn-dark">Переглянути чернетку</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <button :disabled="isCopyProcess" type="button" @click="copy()" class="btn btn-success">
                                <span v-if="isCopyProcess" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-else>Копіювати</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import { calculate } from '../../api/offer';
const FileDownload = require('js-file-download');
import { showOrder, concludeOrder } from '../../api/order';
import { customerMixin } from "../../mixin";
import {list as calculateTourism} from "@/api/tourismInsuranceCompany";
import Vue from "vue";

export default {
    components: {Multiselect},
    mixins: [customerMixin],
    data: function () {
        return {
            loadData: false,
            skeletonOff: false,
            isCopyProcess: false,
            togglerVisible: true,
            documentDisabled: true,
            orderPeriod: '1 рік',
            humans: [],
            birthDates: [],
            order: {
                offer: {},
                contract: {},
                companyInfo: {},
                insuranceObject: {},
                insurant:{
                    documentation: {},
                }
            }
        };
    },

     async created() {
        this.order = await showOrder(this.$route.params.orderId).then((response) => {
            return response.data.data;
        });

         this.loadData = true;
         this.skeletonOff = true;

         this.getContractPeriod();
     },
    computed : {
    },
    mounted () {
        this.getContractPeriod();
    },

    methods: {
        showPriceDgo() {
            return parseFloat((this.order?.dgo?.price || 0));
        },
        async copy() {
            this.isCopyProcess = true;
            const startDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)).format('DD.MM.YYYY');

            let offer = {};
            this.humans = [
                {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
                {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
                {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
                {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [], id:4},
                {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
                {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
            ]
            this.birthDates = [];
            this.fillHumans(this.order.insurants);

            const calculateDate =  this.calculateDate(startDate, this.order.offer.period_option, this.order.offer.yearProgram);

            const paramsTourism = {
                date: calculateDate.startDate,
                end: calculateDate.endDate,
                days: this.order.offer.period_option,
                country: {id:this.order.offer.country.id,ewa_id:this.order.offer.country.ewa_id},
                tourists: this.order.offer.tourists,
                humans: this.humans,
                yearProgram: this.order.offer.yearProgram,
                birthDates: this.birthDates,
                filters: {
                    "franchise": "",
                    "options_list": [],
                    "activities": {
                        "name": "Без активності",
                        "value": "no_activities"
                    },
                    "activitiesItem": "Без активностей"
                },
                activities: "no_activities",
            };


            let responseTourism = await calculateTourism(paramsTourism);

            offer = this.$_.find(responseTourism?.data?.data, (item) => {
                return item.company.id == this.order.companyInfo.companyId && item.tripProgram == this.order.offer.trip_program && item.tourists == this.order.offer.tourists && parseInt(item.coverage) == parseInt(this.order.offer.coverage) && item.franchise == this.order.offer.franchise && item.covid_help == this.order.offer.covid_help;
            });

            let userInfo = {
                "identificationCode":this.order.insurant.documentation.uin,
                "withoutIdentificationCode":this.order.insurant.documentation.withoutUin,
                "name":this.order.insurant.name,
                "surname":this.order.insurant.surname,
                "dateBirth":this.order.insurant.dateBirthAt,
                "phone":this.order.insurant.phone,
                "mail":this.order.insurant.email,
                "city":this.order.insurant.cityObject,
                "street":this.order.insurant.street,
                "house":this.order.insurant.house,
                "apartment":this.order.insurant.apartment,

                // new
                "documentation": {
                    type: this.order.insurant.documentation.documentType,
                    serial: this.order.insurant.documentation.serial,
                    number: this.order.insurant.documentation.number,
                    issuedBy: this.order.insurant.documentation.issuedBy,
                    dateOfIssue: this.order.insurant.documentation.dateOfIssue,
                    endDateOfIssue: this.order.insurant.documentation.endDateOfIssue,
                },
                "documentType":this.order.insurant.documentation.documentType,
                "passportSerial":this.order.insurant.documentation.serial,
                "passportNumber":this.order.insurant.documentation.number,
                "passportDate":this.order.insurant.documentation.dateOfIssue
            };
            let TouristsList = [];

            this.order.insurants.forEach((insurant) => {
                TouristsList.push({ name: insurant.name, surname: insurant.surname, dateBirth: insurant.dateBirthAt, identificationCode: insurant.documentation.uin,
                  withoutIdentificationCode: insurant.documentation.withoutUin,
                    passportSerial: insurant.documentation.serial, passportNumber: insurant.documentation.number, passportDate: insurant.documentation.dateOfIssue});
            })

            if (!offer?.offerId) {
                this.$store.commit('CLEAR_TOURISM_FULL_ORDER');
                this.$store.commit('CLEAR_TOURISM_INFO_TOURISTSLIST');
                this.$store.commit('CLEAR_SEARCH_TOURISM_PARAMS');
                this.$store.commit('CLEAR_TOURISM_INFO_OFFER');
                this.$store.commit('CLEAR_TOURISM_INFO_USER');
                this.$store.commit('SET_SEARCH_TOURISM_PARAMS', paramsTourism);
                this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
                this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsList);

                this.$router.push({name: `tourismResult`});

                return;
            }

            this.order.price = offer.price;
            this.order.offer.id = offer.offerId;
            this.order.offer.available_documents = offer.available_documents ?? null;

            this.declare(true);
        },
        draft() {
            window.open(`/api/orders/draft/${this.$store.getters.USER.id}/${this.$route.params.orderId}`);
        },
        conclude() {
            concludeOrder(this.order.id)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.$alert("ОСЦПВ вдало оформлено!", '',);
                        this.$router.push({name: "history"});

                        return true;
                    }

                    this.$alert("ОСЦПВ не оформлено!", '', 'error');
                }).catch((error) => {
                    this.$alert("ОСЦПВ не оформлено", '', 'error');
                });
        },
        showPrice() {
            let price = parseFloat(this.order.price) + parseFloat((this.order?.dgo?.price || 0));

            if (isNaN(price)) {
                return 0;
            }

            return price.toFixed(2);
        },
        fillHumans: function(insurants){
            insurants.forEach((insurant) => {
                const birthDate = moment(insurant.dateBirthAt, 'DD.MM.YYYY')
                const age = moment().diff(birthDate, 'years');
                if (age < 1) {
                    this.humans[0].value++;
                    this.humans[0].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 3) {
                    this.humans[1].value++;
                    this.humans[1].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 18) {
                    this.humans[2].value++;
                    this.humans[2].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 70) {
                    this.humans[3].value++;
                    this.humans[3].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 76) {
                    this.humans[4].value++;
                    this.humans[4].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                } else if (age < 80) {
                    this.humans[5].value++;
                    this.humans[5].birthDates.push(insurant.dateBirthAt);
                    this.birthDates.push(insurant.dateBirthAt);
                }
            })
        },
        calculateDate(startDate, period_option, yearProgram) {
            // Преобразуем строку в объект moment
            let startMoment = moment(startDate, 'DD.MM.YYYY');

            // Получаем сегодняшнюю дату
            const today = moment().startOf('day');

            // Если startMoment меньше или равен сегодняшней дате, установим startMoment на завтрашний день
            if (startMoment.isSameOrBefore(today)) {
                startMoment = startMoment.add(1, 'day');
            }

            // Установим endMoment на period_option дней после startMoment
            let endMoment = startMoment.clone().add(period_option, 'days');

            // Если установлен флаг yearProgram, увеличим endMoment на год
            if (yearProgram) {
                endMoment = startMoment.clone().add(1, 'year').subtract(1, 'day');
            }

            // Возвращаем новые даты в формате 'дд.мм.гггг'
            return {
                startDate: startMoment.format('DD.MM.YYYY'),
                endDate: endMoment.format('DD.MM.YYYY')
            };
        },
        showDiscountPrice() {
            if (this.order.discountPrice) {
                let price = parseFloat(this.order.discountPrice);

                if (isNaN(price)) {
                    return 0;
                }

                return price.toFixed(2);
            }

            return 0;
        },
        declare(isCopy = false) {
            let offerInfo = {
                "id":this.order.offer.external_id,
                "company":this.order.companyInfo.info,
                "tripProgram":this.order.offer.trip_program,
                "title":this.order.companyInfo.name,
                "options":this.order.offer.options,
                "programs":this.order.offer.programs,
                "members":this.order.offer.members,
                "external_id":this.order.offer.external_id,
                "startDate":this.order.offer.startDate,
                "endDate":this.order.offer.endDate,
                "moduleId":this.order.moduleId,
                "period_option":this.order.offer.period_option,
                "risks":this.order.offer.risks,
                "ages":this.order.offer.ages,
                "price":this.order.offer.price,
                "limit":this.order.offer.coverage,
                "offerId":this.order.offer.id,
                "purposeOfTrip":this.order.offer.purposeOfTrip,
                "fullPrice":this.order.allPrice,
                "available_documents": this.order.offer?.available_documents ?? null,
                "allowed_documents": this.order.offer?.allowed_documents ?? null,
            };
            offerInfo.company.id = this.order.companyInfo.companyId;

            this.birthDates = [];

            this.humans = [
                {name: 'Діти до 1 року ', value: 0, min: 0, max: 1, birthDates: [], id:1},
                {name: 'Діти до 3 років ', value: 0, min: 2, max: 3,  birthDates: [], id:2},
                {name: 'Підлітки до 18 років ', value: 0, min: 4, max: 18,  birthDates: [], id:3},
                {name: 'Дорослі до 69 років ', value: 0, min: 19, max: 69, birthDates: [], id:4},
                {name: 'Дорослі 70-75 років ', value: 0, min: 70, max: 75, birthDates: [], id:5},
                {name: 'Літні 76-100 років ', value: 0, min: 76, max: 100, birthDates: [], id:6}
            ];

            this.fillHumans(this.order.insurants);

            let searchInfo = {
                end: this.order.contract.finishedAt,
                date: this.order.contract.startedAt,
                days: this.order.offer.period_option,
                country: {id:this.order.offer.country.id,ewa_id:this.order.offer.country.ewa_id,name:this.order.offer.country.name},
                tourists: this.order.offer.tourists,
                humans: this.humans,
                yearProgram: this.order.offer.yearProgram,
                birthDates: this.birthDates,
                filters: {
                    "franchise": "",
                    "options_list": [],
                    "activities": {
                        "name": "Без активності",
                        "value": "no_activities"
                    },
                    "activitiesItem": "Без активностей"
                },
                activities: "no_activities",
            };

            let userInfo = {
                "identificationCode":this.order.insurant.documentation.uin,
                "withoutIdentificationCode":this.order.insurant.documentation.withoutUin,
                "name":this.order.insurant.name,
                "surname":this.order.insurant.surname,
                "dateBirth":this.order.insurant.dateBirthAt,
                "phone":this.order.insurant.phone,
                "mail":this.order.insurant.email,
                "city":this.order.insurant.cityObject,
                "street":this.order.insurant.street,
                "house":this.order.insurant.house,
                "apartment":this.order.insurant.apartment,

                // new

                "documentation": {
                    type: this.order.insurant.documentation.documentType,
                    serial: this.order.insurant.documentation.serial,
                    number: this.order.insurant.documentation.number,
                    issuedBy: this.order.insurant.documentation.issuedBy,
                    dateOfIssue: this.order.insurant.documentation.dateOfIssue,
                    endDateOfIssue: this.order.insurant.documentation.endDateOfIssue,
                },


                "documentType":this.order.insurant.documentation.documentType,
                "passportSerial":this.order.insurant.documentation.serial,
                "passportNumber":this.order.insurant.documentation.number,
                "passportDate":this.order.insurant.documentation.dateOfIssue
            };

            let TouristsListInfo = [];

            this.order.insurants.forEach((item, key) => {
                TouristsListInfo.push({
                    "name":item.name,
                    "surname":item.surname,
                    "dateBirth":item.dateBirthAt,
                    "identificationCode":item.documentation.uin,
                    "withoutIdentificationCode":item.documentation.withoutUin,
                    "passportSerial":item.documentation.serial,
                    "passportNumber":item.documentation.number,
                    "passportDate":item.documentation.dateOfIssue,
                    "passportIssuedBy":item.documentation.issuedBy,
                    "passportEndDate":item.documentation.endDateOfIssue,
                    "documentType": item.documentation.documentType,
                });
            });

            let params = {
                type: "next",
                statusId: 0
            };
            this.$store.commit('CLEAR_TOURISM_INFO_TOURISTSLIST');
            this.$store.commit('CLEAR_SEARCH_TOURISM_PARAMS');
            this.$store.commit('CLEAR_TOURISM_INFO_OFFER');
            this.$store.commit('CLEAR_TOURISM_INFO_USER');
            this.$store.commit('CLEAR_TOURISM_FULL_ORDER');
            this.$store.commit('SET_TOURISM_INFO_OFFER', offerInfo);
            this.$store.commit('SET_TOURISM_INFO_USER', userInfo);
            this.$store.commit('SET_TOURISM_INFO_TOURISTSLIST', TouristsListInfo);
            this.$store.commit('SET_SEARCH_TOURISM_PARAMS', searchInfo);
            this.orderId  = (isCopy) ? null : this.order.id;
            this.$store.commit('SET_TOURISM_FULL_ORDER', this.orderId);

            this.$router.push({name: 'tourismSteps', query: {from: 'history'}});
        },
        showTypeDocument() {
            if (this.order.insurant?.documentation?.type) {
                return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, { id: this.order.insurant.documentation.type })?.name;
            }
        },
        showAutoCategory() {
            if (this.order.insuranceObject.autoCategory) {
                return `(${this.order.insuranceObject.autoCategory})`;
            }
        },

        showCityRegister() {
            if (this.order.insurant.city) {
                return this.order.insurant.city;
            }
            /*if (this.order.insurant.city) {
                return this.order.offer.city.name_full_name_ru;
            }*/
        },
        euroStatus(value){
            $('[data-toggle="tooltip"]').tooltip('hide');
            if(this.euroChecked === true){
                this.city = value;
                $('.city-select').addClass('disabled');
            }else{
                this.city = null;
                $('.city-select').removeClass('disabled');
            }
        },
        isLegalType() {
            //вынести это в миксин
          return this.order.insurant.customerTypeId === 2;
        },
        choseCity(value) {
            this.city = value;
            $(".tooltip").remove();
            this.euroChecked = false;
            $('.params-city-status').removeClass('disabled');
        },
        getContractPeriod(){
            if(this.order.offer.yearProgram){
                this.orderPeriod = '1 рік';
            }else{
                if(this.order.offer.period_option == 1){
                    this.orderPeriod = this.order.offer.period_option+' день'
                }else if(this.order.offer.period_option > 1 && this.order.offer.period_option < 5){
                    this.orderPeriod = this.order.offer.period_option+' дні'
                }else {
                    this.orderPeriod = this.order.offer.period_option+' днів'
                }
            }
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

